/* * {
  margin: 0;
  padding: 0;
}
.conainer {
  width: 600px;
  margin: 100px auto;
  font-family: "Arial";
}
.circle_percent {
  font-size: 200px;
  width: 1em;
  height: 1em;
  position: relative;
  background: #eee;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin: 20px;
}
.circle_inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  clip: rect(0 1em 1em 0.5em);
}
.round_per {
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  background: #e4a6d2;
  clip: rect(0 1em 1em 0.5em);
  transform: rotate(180deg);
  transition: 1.05s;
}
.percent_more .circle_inner {
  clip: rect(0 0.5em 1em 0em);
}
.percent_more:after {
  position: absolute;
  left: 0.5em;
  top: 0em;
  right: 0;
  bottom: 0;
  background: #e4a6d2;
  content: "";
}
.circle_inbox {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #fff;
  z-index: 3;
  border-radius: 50%;
}
.percent_text {
  position: absolute;
  font-size: 36px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}


 */
.vertical-centered-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-progrees-loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000b5;
  z-index: 111111111;
}

.circlePercent {
  position: relative;
  top: 26px;
  left: 26px;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: rgb(253, 194, 2);
}

.circlePercent:before,
.circlePercent > .progressEnd {
  position: absolute;
  z-index: 3;
  top: 2px;
  left: 45px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
  -ms-transform-origin: 3px 46px;
  transform-origin: 3px 46px;
  content: "";
}

.circlePercent:after,
.circlePercent > .progress {
  position: absolute;
  -ms-transform-origin: 48px 48px;
  transform-origin: 48px 48px;
  z-index: 0;
  top: 0;
  left: 0;
  width: 48px;
  height: 96px;
  border-radius: 48px 0 0 48px;
  background: rgb(253, 194, 2);
  content: "";
}

.circlePercent.fiftyPlus:after {
  background: white;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.circlePercent > .progress.progress {
  background: white;
}

.circlePercent > .counter {
  position: absolute;
  box-sizing: border-box;
  z-index: 2;
  width: 100px;
  height: 100px;
  margin-top: -2px;
  margin-left: -2px;
  border-radius: 50%;
  border: 4px solid rgb(253, 194, 2);
}

.circlePercent > .counter:before {
  position: absolute;
  z-index: 1;
  top: 0;
  margin-top: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  line-height: 26px;
  font-family: sans-serif;
  text-align: center;
  color: white;
  content: attr(data-percent) "%";
  display: flex;
  align-items: center;
  justify-content: center;
}

.circlePercent > .counter:after {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background: rgb(253, 194, 2);
  content: "";
}

.circlePercent > .counter[data-percent="100"] {
  background: white;
}

.figure {
  position: absolute;
  top: 8px;
  right: 40px;
  font-size: 42px;
  z-index: 9999;
  font-weight: 600;
  color: #fff;
}
